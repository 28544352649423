@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  font-family: "Roboto", sans-serif;
}

.nav-link {
  font-size: 1.5 rem;
  font-weight: bold;
}

.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.navbar.scrolled {
  background-color: white;
  transition: background-color 0.3s ease;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar.scrolled .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler.open .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.blur-background {
  backdrop-filter: blur(10px);
  transition: backdrop-filter 0.3s ease;
}

.nav-link {
  position: relative;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar.scrolled {
  background-color: #f2f2f2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.navbar.scrolled .nav-link {
  color: #000b0d;
}

.navbar-nav .nav-link.active {
  color: red;
}
.navbar .nav-link {
  color: #f2f2f2;
  transition: color 0.3s ease;
}

.hero-section,
.about-hero,
.services-hero,
.contact-hero,
.portfolio-hero {
  position: relative;
  background: url("../images/bg.jpeg") no-repeat center center;
  background-size: cover;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-hero, .services-hero, .contact-hero, .portfolio-hero {
  min-height: 50vh;
}
.hero-section::before,
.about-hero::before,
.services-hero::before,
.contact-hero::before,
.portfolio-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 71, 0.8);
  z-index: 1;
}

.hero-section h1,
.services-hero h1,
.services-hero p,
.contact-hero h1,
.contact-hero p,
.portfolio-hero h1,
.portfolio-hero p,
.about-hero h1,
.about-hero p,
.hero-section p,
.hero-section button {
  position: relative;
  z-index: 2;
  color: #f2f2f2;
}

.card {
  width: 21rem;
}
.card-img-top {
  width: 100%;
  height: 350px;
}
.link{
  text-decoration: none;
}